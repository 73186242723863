(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";

  var Cover101CoreMiniPos = function ($scope, $) {
    this.$scope = $scope;
    this.$element = $scope.find('#mini-pos-widget');
    this.init();
  };
  Cover101CoreMiniPos.prototype = {
    init: function () {
      var self = this;
      self.prepareForm();
      self.addItemToCart();
      self.initStickySidebar();
    },
    addItemToCart: function () {
      var self = this;
      self.$element.on('click', '#add-to-cart', function () {
        var $this = $(this);
        var product_id = self.$element.find('#duration').find(':selected').data('id');
        var duration = self.$element.find('#duration').val();
        var adults = parseInt(self.$element.find('#adult').val()) || 0;
        var juniors = parseInt(self.$element.find('#junior').val()) || 0;
        var country = self.$element.find('#country').val();
        var countries = country ? [country] : [];
        if (product_id === undefined || duration === '' || adults == 0 && juniors == 0 || countries.length === 0) {
          var alertHtml = '<div class="alert alert-warning alert-dismissible fade show" role="alert">' + 'Please make sure all required fields are selected.' + '<span class="btn-close" data-bs-dismiss="alert" aria-label="Close"></span>' + '</div>';
          self.$element.prepend(alertHtml);
          setTimeout(function () {
            self.$element.find('.alert').alert('close');
          }, 6000);
          return false;
        }
        var data = {
          action: 'add_cover101_item',
          product_id: product_id,
          duration: duration,
          adults: adults,
          juniors: juniors,
          countries: countries,
          nonce: pos_ajax_obj.nonce
        };
        $(document.body).trigger('adding_to_cart', [$this, data]);
        $.post(pos_ajax_obj.ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'), data, function (response) {
          if (!response) {
            return;
          }
          let cart_url = response.fragments.cart_url;
          let i18n_view_cart = response.fragments.i18n_view_cart;
          let this_page = window.location.toString();
          this_page = this_page.replace('add-to-cart', 'added-to-cart');
          if (response.error && response.product_url) {
            window.location = response.product_url;
            return;
          }
          let fragments = response.fragments;
          let cart_hash = response.cart_hash;
          if (fragments) {
            $.each(fragments, function (key, value) {
              $(key).addClass('updating');
            });
          }
          setTimeout(function () {
            $('.mini-cart-header .mini-cart-inner').addClass('open');
          }, 1000);
          if (!response.is_cart && $this.parent().find('.added_to_cart').size() === 0) {
            $this.after(' <a href="' + cart_url + '" class="added_to_cart wc-forward" title="' + i18n_view_cart + '">' + i18n_view_cart + '</a>');
          }
          if (fragments) {
            $.each(fragments, function (key, value) {
              $(key).replaceWith(value);
            });
          }
          $('.shop_table.cart').load(this_page + ' .shop_table.cart:eq(0) > *', function () {
            $('.shop_table.cart').stop(true).css('opacity', '1').unblock();
            $(document.body).trigger('cart_page_refreshed');
          });
          // Update woocommerce
          $(document.body).trigger('added_to_cart', [fragments, cart_hash, $this]);
          // Update mini-cart items
          $('.mini-cart-items').html(fragments.cart_items_count);
          self.$element.find('#adult').val(0);
          self.$element.find('#junior').val(0);
          self.$element.find('#country').val('Kenya').trigger('change');
          self.$element.find('.basket-amount').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>0.00</bdi></span>');
          self.$element.find('select#duration').val('14 Days').trigger('change');
          $(document).find('#more-covers').show();
        });
      });
    },
    formatOption: function (option) {
      if (!option.id) {
        return option.text;
      }
      var textParts = option.text.split('|');
      var duration = textParts.length > 1 ? textParts[1].trim() : option.text;
      var $option = $('<div class="option-content">' + '<i class="icon fa fa-circle"></i>' + '<div>' + duration + '</div>' + (option.element.dataset.description ? '<div class="description">' + option.element.dataset.description + '</div>' : '') + '</div>');
      return $option;
    },
    prepareForm: function () {
      var self = this;
      self.$element.find('#country').select2({
        templateResult: this.formatOption.bind(this),
        templateSelection: this.formatOption.bind(this),
        dropdownParent: self.$element
      });
      self.$element.find('#duration').select2({
        templateResult: this.formatOption.bind(this),
        templateSelection: this.formatOption.bind(this),
        dropdownParent: self.$element
      });
      self.$element.find('#adult, #junior').on('input', function () {
        this.value = this.value.replace(/[^0-9]/g, '');
        if (this.value > 100) {
          this.value = 100;
        }
      });
      self.$element.find('select#duration').on('change', function () {
        self.getProductPrice();
      });
      self.$element.find('#adult, #junior, #country').on('change', function () {
        self.getProductPrice();
      });
      self.$element.find('#closeBtn').on('click', function () {
        self.$element.addClass('hidden');
        $('#reopenIcon').addClass('show');
      });
      $('#reopenIcon').on('click', function () {
        self.$element.removeClass('hidden');
        $('#reopenIcon').removeClass('show');
      });
      $(document).find('#more-covers').show();
    },
    initStickySidebar: function () {
      var self = this;
      self.$element.find('#mini-pos-widget').stickySidebar({
        topSpacing: 200,
        bottomSpacing: 20,
        containerSelector: 'body',
        innerWrapperSelector: '#mini-pos-widget',
        resizeSensor: true,
        stickyClass: 'is-affixed',
        minWidth: 768
      });
    },
    getProductPrice: function () {
      var self = this;
      var product_id = self.$element.find('#duration option:selected').data('id');
      var duration = self.$element.find('#duration').val();
      var adults = parseInt(self.$element.find('#adult').val()) || 0;
      var juniors = parseInt(self.$element.find('#junior').val()) || 0;
      var country = self.$element.find('#country').val();
      var countries = country ? [country] : [];
      if (countries.length === 0 || adults === 0 && juniors === 0 || !duration) {
        return;
      }
      $.ajax({
        url: pos_ajax_obj.ajax_url,
        method: 'POST',
        data: {
          action: 'get_product_price',
          nonce: pos_ajax_obj.nonce,
          product_id: product_id,
          adults: adults,
          juniors: juniors,
          countries: countries,
          duration: duration
        },
        success: function (response) {
          if (response.success) {
            self.$element.find('.basket-amount').html(response.data.price);
          } else {
            var alertHtml = '<div class="alert alert-warning alert-dismissible fade show" role="alert">' + response.data + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' + '</div>';
            self.$element.prepend(alertHtml);
            setTimeout(function () {
              self.$element.find('.alert').alert('close');
            }, 6000);
          }
        }
      });
    }
  };
  $(window).on('elementor/frontend/init', function () {
    elementorFrontend.hooks.addAction('frontend/element_ready/cover_mini_pos.default', function ($scope, $) {
      new Cover101CoreMiniPos($scope, $);
    });
  });
})(jQuery);

},{}]},{},[1]);
